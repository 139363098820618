import { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import socketio from "socket.io-client";
import PhoneX from "./icons/PhoneX";
import Microphone from "./icons/Microphone";
import FAQS from "./FAQs";
import MicrophoneMuted from "./icons/MicrophoneMuted";

const socket = socketio(process.env.REACT_APP_SERVER_URL, {
    autoConnect: false
});

export default function CallInterface(){

    const location = useLocation()
    const navigate = useNavigate()
    const [isStateAuthed, setIsStateAuthed] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isMuted, setIsMuted] = useState(false)

    const audioContextRef = useRef(null);
    const userCanvasRef = useRef(null);
    const queuePlayingRef = useRef(false);
    const wakeLockRef = useRef(null);
    const queueRef = useRef([]);
    const botAudioPlayRef = useRef(null);
    const audioPlayRef = useRef(null);
    const streamRef = useRef(null);
    const sourceRef = useRef(null);
    const audioNodeRef = useRef(null);

    const acquireScreenWakeLock = () => {
        try {

            if ("wakeLock" in navigator) {

                navigator.wakeLock.request("screen")
                    .then((wakeLock) => {
                        console.log("Screen wake Locked while having the conversation...");
                        wakeLockRef.current = wakeLock;
                    })
                    .catch(() => {
                        console.log("Screen wake Locking failed...")
                    })

            } else {
                console.log("Wake Lock feature is not available in this browser...");
            }

        } catch (err) {
            console.log("Error in acquireScreenWakeLock function...");
        }
    }

    const releaseScreenWakeLock = () => {
        try {
            if (wakeLockRef.current) {
                wakeLockRef.current.release()
                    .then(() => {
                        console.log("Screen wake lock released successfully...");
                    })
                    .catch(() => {
                        console.log("Screen wake lock release failed...");
                    })
            }
        } catch (err) {
            console.log("Error in releaseScreenWakeLock Function...");
        }
    }

    const drawOntoUserCanvas = (ref, audioSample) => {
        if(audioSample && audioSample.length === 0) return;

        const bufferLength = audioSample.length;

        const canvas = ref.current;
        if (!canvas) return;

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        const width = canvas.width;
        const height = canvas.height;
        const mid = height / 2
        
        ctx.clearRect(0, 0, width, height);
        ctx.lineWidth = 1.5;

        // Set bar properties
        const barWidth = (canvas.width / bufferLength);
        let barHeight;
        let x = 0;

        ctx.beginPath();
        // Draw the bars
        
        for (let i = 0; i < bufferLength; i++) {
            barHeight = Math.abs(audioSample[i] * 3000);
            if(barHeight >= canvas.height) barHeight = canvas.height * 0.8;
            //Go to mid
            ctx.moveTo(x, mid);

            // Set color for the bars
            ctx.strokeStyle = "#0D9E3A";

            // Draw the arc
            ctx.lineTo(x, mid - barHeight / 4);

            ctx.moveTo(x, mid);
            ctx.lineTo(x, mid + barHeight / 4);

            
            x += barWidth + 1;
        }
        ctx.stroke();
    }

    const playAudioChunk = async (audioSamples) => {

        if (botAudioPlayRef.current) {
            botAudioPlayRef.current.pause();
        }

        audioPlayRef.current = new (window.AudioContext || window.webkitAudioContext)()
      
        const audioContext = audioPlayRef.current;

        try{
            const buffer = audioContext.createBuffer(1, audioSamples.length, 16000);
            buffer.copyToChannel(Float32Array.from(audioSamples), 0);
            // buffer.copyToChannel(audioSamples, 0);
            const source = audioContext.createBufferSource();
            source.buffer = buffer;
            source.connect(audioContext.destination);
    
            // Start playing the audio chunk
            source.start();
            console.time("audio")
            console.log("playing audio chunk");

            source.onended = () => {
                console.log("audio chunk ended")
                console.timeStamp("audio")
                console.timeEnd("audio")
                playNextChunk()
            }
        } catch (e) {
            console.log("error",e)
        }
    };

    const playNextChunk = () => {
        
        if (queueRef.current.length > 0) {
            let chunkToPlay = []
            if(queueRef.current.length > 3){
                console.log("if");
                
                chunkToPlay = queueRef.current.splice(0,3)
            } else {
                console.log("else",queueRef.current.length);
                chunkToPlay = queueRef.current.shift();
            }

            playAudioChunk(chunkToPlay);
        } else {
          queuePlayingRef.current = false; // Indicate that playback is paused until more data arrives
        }
    };


    const handleStreamData = (chunk) => {
        queueRef.current.push(chunk);
        if (!queuePlayingRef.current) {
            queuePlayingRef.current = true;
            playNextChunk();
        }
    };

    const startStreaming = async () => {
        try {
            let audioContext = new AudioContext({ sampleRate: 16000 })
            audioContextRef.current = audioContext

            await audioContext.audioWorklet.addModule('myAudioWorklet.js');

            const audioNode = new AudioWorkletNode(audioContext, 'myAudioWorklet');
            audioNodeRef.current = audioNode;

            let i = 1, chunk = [], chunkNumber = 1;

            // Listen for messages from the AudioWorkletProcessor
            audioNode.port.onmessage = (event) => {
                drawOntoUserCanvas(userCanvasRef, event.data)
                // console.log('Received audio chunk:', audioChunk);
                chunk = [...chunk, ...event.data];

                if (i === 25) {
                    // console.log(`--------------------------------> sending audio chunk ${chunkNumber} to server--------------------------------`);
                    
                    // console.log(`${i} chunks sampled at 128 buffer size at sampling rate of ${sourceNode.context.sampleRate}`, chunk);
                    socket.emit("audioStream", { audioData: chunk, botId: "", botName: "Tesla bot", language: "English", languageShortForm: "en", chunkNumber, });
                    // handleStreamData(chunk)
                    chunk = [];
                    i = 1;
                    chunkNumber++;
                    return;
                }

                i += 1;
            };

            // Connect the node to process audio
            audioNode.connect(audioContext.destination);


            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            streamRef.current = stream;
            const source = audioContext.createMediaStreamSource(stream);
            sourceRef.current = source;
            source.connect(audioNode);
        } catch (err) {
            console.error(err);
        }
    }

    const toggleSound = () => {
        setIsMuted(!isMuted)
        streamRef.current.getAudioTracks().forEach(track => track.enabled = isMuted);
    }

    const cutCall = () => {
        try {

            if (audioContextRef.current && audioContextRef.current.state !== "closed") {
                audioContextRef.current.close();
            }

            if(audioPlayRef.current && audioPlayRef.current.state !== "closed") {
                //closing because some chunks can be played after we resume playback
                audioPlayRef.current.close();
            }

            if (botAudioPlayRef?.current) {
                botAudioPlayRef.current.pause();
            }

            if(audioNodeRef.current) audioNodeRef.current.disconnect();

            if(sourceRef.current) sourceRef.current.disconnect();

            if(queuePlayingRef.current) queuePlayingRef.current = false;

            if(streamRef.current) {
                streamRef.current.getTracks().forEach(track => track.stop());
                streamRef.current = null;
            }

            queueRef.current = []

            releaseScreenWakeLock();

            if(socket.connected) socket.disconnect();
            location.state = {}
            // navigate("/")
            window.location.href = "/"

            return true;

        } catch (err) {
            console.log("Error while cutting the call...");
            return false;
        }
    }

    useEffect(() => {
        if(location.state && location.state.connectCall && location.state.callId){
            setIsStateAuthed(true)
        } else {
            navigate("/")
        }
    },[location, navigate])

    useEffect(() => {
        window.addEventListener("beforeunload", cutCall)

        return () => {
            window.removeEventListener("beforeunload", cutCall)
        }
    },[])

    useEffect(() => {
        if(isStateAuthed) {
            socket.connect()

            socket.on("greeting", (data) => {
                console.log("Greeting from server with socket Id: ", data.socketId);
                socket.emit("connect-call",{aiSocketId: location?.state?.callId})
            });
            
            socket.on("audioStream", (audioStream) => {
                console.timeStamp("audioStream");
                console.log("Received audio stream from server...");
                console.timeEnd("audioStream")
                console.time("audioStream")
                handleStreamData(audioStream?.audioData)
            })
                
            socket.on("call-started", () => {
                acquireScreenWakeLock();
                startStreaming();
                setLoading(false);
            })

            socket.on("call-ended", () => {
                cutCall()
            })

            socket.on("disconnect", () => {
                cutCall()
            })

        } else{
            if(socket.connected) socket.disconnect()
            releaseScreenWakeLock()
        }
    },[isStateAuthed])

    if(loading) {
        return (
            <div className="grid grid-flow-col gap-3 place-content-center">
                <div role="status">
                    <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin fill-primary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                </div>
                <span className="text-xl font-semibold">Starting your call shortly...</span>
            </div>
        )
    }

    return(
        <div className="grid bg-app-home-hero-bg-gradient">
            <div className="bg-white flex flex-col gap-8 p-8 place-self-center h-5/6 rounded-3xl border border-dashed border-border-off">
                <div className="grid grid-rows-[auto_1fr] gap-2 flex-grow">
                    <h2 className="font-semibold text-2xl">You are now talking to Tesla voicebot</h2>
                    
                    <div className="flex gap-4">
                        <FAQS size={"small"}/>

                        <div className="grid gap-4 w-[500px]">
                            <div style={{backgroundImage: "url('/images/cb-icon.webp')"}} className="grid w-28 h-28 rounded-full justify-self-center bg-cover bg-no-repeat bg-center">

                            </div>
                            <div className="grid relative">
                                <canvas ref={userCanvasRef} width={500} height={100} />
                                {isMuted && (
                                    <div className="px-8 absolute py-4 bg-red-400 justify-self-center text-white rounded-xl border border-red-600 w-fit">
                                        You are muted!
                                    </div>
                                )}
                            </div>
                        </div>


                    </div>

                </div>

                <div className="flex gap-4 place-content-center border-t-[2px] border-dashed pt-4">

                    <button onClick={toggleSound} className="w-12 h-12 grid place-content-center hover:bg-gray-100 border border-border-off rounded-full">
                        {isMuted ? <MicrophoneMuted/> : <Microphone/>}
                    </button>
                    
                    <button onClick={cutCall} className="w-12 h-12 grid place-content-center hover:bg-red-900 bg-red-700 text-white rounded-full">
                        <PhoneX/>
                    </button>
                </div>
            </div>
        </div>
    )
}